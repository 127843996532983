// 引入基本模板
import * as echarts from 'echarts';
// 引入饼图组件
require('echarts/lib/chart/pie');
// 引入柱状图组件
require("echarts/lib/chart/bar");
// 引入提示框和title组件
require('echarts/lib/component/tooltip');
require('echarts/lib/component/title');
require("echarts/lib/component/legend");

import { GridComponent,  
  TitleComponent,
  TooltipComponent,
  LegendComponent 
} from "echarts/components";
import { BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  BarChart,
  GridComponent,
  CanvasRenderer
]);

// 引入自定义函数
import { sleep, startLoading, endLoading } from "@/utils/function";
import {
  domainUrl,
  studentGroupPageSize,
  studentScorePageSize,
  studentGamePageSize,
  studentCertPageSize,
} from "@/utils/const";

//百度地图
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import DownLoad from "../../../../components/DownLoad.vue";
import API from '@/api/teacher/group/groupList';

import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";

export default {
  name: "other",
  components: {
    BaiduMap,
    DownLoad
  },
  data() {
    return {
      // 班级码图片链接
      groupCodeImgSrc:'',
      // 当前时间
      timenow:'',
      // 群组码对话框
      groupDialogVisible:false,
      // 发送通知对话框
      sendNoticeDialogVisible:false,
      // 通知内容
      noticeValue:'',
      // 班级是否可拖拽排序
      sortAbled: false,
      // 安全准入搜索关键词
      certSearch: '',
      // 班级成绩搜索关键词
      studentGroupSearch: '',
      classCourseList: [],
      isRecover: true,
      isShow: true,
      // 安全教育小游戏数据列表弹窗标题
      gamedataDialogTitle: '',
      // 安全教育小游戏数据列表用户id
      user_id: '',
      // 安全教育小游戏数据列表
      gamedataList: [],
      // 安全教育小游戏数据列表页码
      gamedatalistPage: 1,
      // 安全教育小游戏数据列表数据总数
      gamedatalistTotal: 0,
      // 安全教育小游戏数据列表弹窗显示与隐藏
      gamedataDialogVisible: false,
      multipleSelection: [],
      // =======================================================
      classTypeList: [
        {
          id: 0,
          name: '在修'
        },
        {
          id: 1,
          name: '重修'
        }
      ],
      groupType: '',
      // 左侧项目列表 数据
      // 已删除班级显示标识
      deleteGroupShowSign: false,
      // 标签页默认选中项
      activeName: "first",
      // 项目列表
      groupList: [],
      // 课程列表
      classList: [],
      // (筛选)课程id
      class_id: 0,
      // 删除项目列表
      deleteGroupList: [],
      // 已删除的项目数量
      deleteNum: 0,
      // 当前正在显示信息的项目id
      currentGroup_id: 0,
      // 右侧tab页内容显示
      rightContentShow: true,
      // 左侧班级弹窗显示标识
      leftDialogVisible: false,
      // 左侧弹窗标题
      leftDialogTitle: "",
      // 左侧弹窗标识
      leftDialogSign: "",
      // 班级id
      group_id: "",
      // 左侧班级id
      left_group_id: "",
      // 班级名称
      groupName: "",
      // 添加/修改班级课程id
      course_id: "",
      // 证书课程id
      cert_course_id: "",

      // ===============================================================
      // 左侧列表数据
      // 学生列表数据
      studentListTableData: [],
      // 学生列表总数
      studentListTotal: 0,
      // 学生列表页码
      studentListPage: 1,
      // 学生列表每页显示数据量
      studentListPageSize: studentGroupPageSize,
      // checkbox 全选标识
      checkAll: false,
      // 多选框选择数据
      checkboxArr: [],
      // 学生列表搜索值
      studentListSearch: "",
      // 弹窗显示标识
      rightStudentDialogVisible: false,
      // 弹窗操作标识
      rightStudentDialogSign: "",
      // 弹窗显示标题
      rightStudentDialogTitle: "",
      // 班级导入学生上传文件路径
      groupFilePath: "",
      // 上传文件列表
      fileList: [
        // {
        //     name: "food.jpeg",
        //     url:
        //         "https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg"
        // }
      ],
      // 添加学号
      studentNumber: "",
      // 解锁需要花费的分数
      lockPoint: "",
      // 上传url
      uploadUrl:
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/uploadExportGroupFile",

      // ==============================================================================
      // 右侧学生成绩数据
      // list数据
      scoreTableData: [],
      // 项目标题数据
      scoreTableTitleList: [],
      // 学生列表总数
      scoreTotal: 0,
      // 学生列表页码
      scorePage: 1,
      // 学生列表每页显示数据量
      scorePageSize: studentScorePageSize,

      // ==============================================================================
      // 右侧学生游戏数据
      // 列表数据
      gameTableData: [],
      // 学生列表总数
      gameTotal: 0,
      // 学生列表页码
      gamePage: 1,
      // 学生列表每页显示数据量
      gamePageSize: studentGamePageSize,

      // ==============================================================================
      // 右侧证书列表数据
      // 列表数据
      certTableData: [],
      // 学生列表总数
      certTotal: 0,
      // 学生列表页码
      certPage: 1,
      // 学生列表每页显示数据量
      certPageSize: studentCertPageSize,
      // 证书信息
      certInfo: {},
      // 小游戏列表
      gamelist: [],
      // 小游戏id
      game_id: "",
      // 游戏类型id
      game_type_id: "",
      // 游戏类型列表
      gameTypelist: [],
      // 小游戏活动id
      // game_active_id:"",
      // 游戏活动列表
      // gameActivelist:[],

      // ==============================================================================
      // 右侧虚拟列表数据
      // 虚拟列表数据
      expList: [],
      // 筛选日期数组['开始时间','结束时间']
      virtualDate: [],
      // 虚拟实验id
      virtualExpId: "",
      // 虚拟数据展示
      virtualDataShow: false,
      // 虚拟统计数据
      virtualData: "",
      // 深拷贝的班级列表数据
      groupList2: [],
      // 虚拟项目列表
      expprojectList: [],
      // 账户总积分
      balance: '',
      // 解锁人数
      lockNum: '',
      // 花费总积分
      totalMoneySpent: '',
      // 控制全部解锁弹窗显示与隐藏
      allunlockDialogVisible: false,
      // 控制统计弹窗显示与隐藏
      stasticDialogVisible: false,
      // 虚拟搜索关键词
      virtualSearch: '',
      // 已获取证书数量
      certNum: 0,
      // 标识
      census:0,
      // 查看类型
      lookType:'1',
      // 导出成绩弹窗
      scoreDialogVisible:false,
      // 原始成绩标识
      rawScoreSign:false,
      // 计算成绩标识
      calcScoreSign:false,
      // 开放时间标识
      openTimeSign:false,

      // ========================================================
      // add by camellia 20230328 学情分析虚拟实验关卡数据统计
      // 数据统计弹窗分页
      statPage:1,
      // 数据统计弹窗标识
      echartsDialogVisible:false,
      // 数据统计弹窗标题
      echartsDialogTitle:'',
      // 数据统计弹窗宽度
      echartsDialogWidth:'70%',
      // 数据统计弹窗分页总数
      echartsDialogTotal:0,
      // 数据统计弹窗统计关卡
      echartsDialogIndex:0,
      // 班级名称
      student_group_name:'',  
      // 班级学生总人数
      studentNum:0,
      // 班级学生注册人数
      studentRegNum:0,

      // ==========================================================
      // add by wyf 2023-05-31 学生总分分布统计
      // 班级学生成绩
      studentScore:{},
      // 学生成绩统计图的显示与隐藏
      studentScoreDialogVisible: false,
      // 存放学生成绩图表的echarts实例
      studentScoreEcharts: null,

    };
  },
  /**
   * @name: 页面创建成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  created() {
    const self = this;
    // 接受路由参数
    self.input = this.$router.currentRoute.query.search;
    self.page = Number(this.$router.currentRoute.query.page);
    self.group_id = this.$router.currentRoute.query.group_id;
    self.virtualExpId = this.$router.currentRoute.query.exp_id;
    self.activeName = this.$router.currentRoute.query.activeName ?? '';
    self.census = this.$router.currentRoute.query.census ?? 0;
    // 初始化 学情分析 时间
    let date = new Date();
    let startDate = date.getFullYear() + "-" +(date.getMonth()+1)+ "-1";
    let endDate = date.getFullYear() + "-" +(date.getMonth()+2)+ "-1";
    self.virtualDate = [startDate,endDate];
    // 获取数据
    self.getGroupList();
  },
  /**
   * @name: 监听查看数据类型改变
   * @author: camellia
   * @date: 2023-03-13
   * @param:	lookType    	number    	1：成绩 2：预约时间
   */
  watch: {
    //  如果需要第一次就执行监听 则需要设置：immediate: true
    lookType: {
      handler(newVal,oldVal) {
        // console.log('oldVal:',oldVal)  
        // console.log('newVal:',newVal)  
        this.lookType = newVal;
        this.getReleaseScore();
      },
      immediate: true
    }, 
  },
  /**
   * @name: 挂载成功之后
   * @author: camellia
   * @date: 2021-03-04
   */
  mounted() 
  {
    const self = this;
    self.rowDrop();
    if (self.groupList.length > 0 && self.group_id == '') 
    {
      sleep(800).then(() => {
        self.updateCssStyle(self.groupList[0].id);
      });
    }
    // 获得初始数据
    /*if(self.census == 1)
    {
      self.courseExpCensus();
    }//*/
  },
  methods: {
    /**
     * @name: 班级码关闭，清除定时器
     * @author: lanxinyu
     * @date: 20210918
     */
    closeTimeIntervel()
    {
      let self=this;
      clearInterval(self.timeIntervel);
    },
    /**
     * @name: 关闭课程二维码弹窗
     * @author: camellia
     * @date: 2022-04-12
     */
    closegroupDialog()
    {
      this.groupDialogVisible = false;
      this.getGroupList();
    },
    /**
     * @name: 获取当前时间
     * @author: lanxinyu
     * @date: 20210918
     */
    getTimeNow()
    {
      let self=this;
      self.timeIntervel=setInterval(() => {
        const time = new Date();
        const now=time.toLocaleString();
        self.timenow=now;
      }, 1000); 
    },
    /**
     * @name: 展示班级码
     * @author: lanxinyu
     * @date: 20210923
     */
    showGroupDialog()
    {
      try 
      {
        let self= this;
        self.getTimeNow();   
        let data = {
          group_id : self.$route.query.group_id + "@@@group"
        }
        nprogressStart();
        self.$Loading.show();
        API.createGroupCode(data)
          .then(res=>{
            nprogressClose();
            self.$Loading.hide();
            if(res)
            {
              self.groupCodeImgSrc = res;
              self.groupDialogVisible=true;
            }
          })
      } 
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message:'系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType:'warning',
          displayTime:1500
        })
      }
    },
    /**
     * @name: 显示发送通知对话框
     * @author: lanxinyu
     * @date: 20210923
     */
    showNoticeDialog()
    {
        this.sendNoticeDialogVisible=true;
    },
    /**
     * @name: 发送通知
     * @author: lanxinyu
     * @date: 20210923
     */
    sendNotice()
    {
        let self=this;
        let data={
          group_id:self.$route.query.group_id,
          content:self.noticeValue,
        }
        console.log(data);
        API.sendWxMessage(data)
          .then((res)=>{
            if(res.code > 0 )
            {
              self.sendNoticeDialogVisible=false
            }
            else
            {
              self.$Tips({
                // 消息提示内容
                message:res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType:'success',
                displayTime:1500
              })
            }
            
          })
    },
    /**
     * @name: 关闭发送通知弹窗，清空通知内容
     * @author: lanxinyu
     * @date: 20220426
     */
    noticeDialogClose()
    {
      this.noticeValue = ''
    },
    /**
     * @name: 锁定页面，不允许拖拽
     * @author: LXY
     * @date: 2022-09-13
     */
    lockSort() 
    {
      this.sortAbled = !this.sortAbled;
      this.updateCssStyle(this.group_id);
    },
    /**
    * @name: 获取班级学生列表
    * @author: camellia
    * @date: 2021-03-05
    */
    getGroupstudentList(id, courseid) 
    {
      // 清空搜索关键词
      this.studentListSearch = '';
      this.studentListPage = 1;
      this.getGroupInfo(id, courseid);
    },
    /**
      * @name: 获取班级当前活动页信息
      * @author: camellia
      * @date: 2021-03-12
      */
    getcurrentActive(id, courseid, name) 
    {
      let self = this;
      self.isShow = true;
      self.virtualExpId = '';
      self.virtualDataShow = false;
      self.isRecover = false;
      self.rightContentShow = true;
      self.group_id = id;
      self.group_name = name;
      self.cert_course_id = courseid;
      sleep(800).then(() => {
        self.updateCssStyle(self.group_id);
      });
      if (self.activeName == 'first') {
        self.getGroupInfo(self.group_id, self.cert_course_id);
        self.getExpList()
      }
      else if (self.activeName == "second") {
        self.getReleaseScore();
      }
      else if (self.activeName == "third") {
        self.getGameList();
      }
      else if (self.activeName == "fourth") {
        self.getCertReckonList();
      }
      else if (self.activeName == "fifth") {
        self.getExpList()
      }
    },
    /**
    * @name: 安全教育小程序表格分页序号递增
    * @author: camellia
    * @date: 2021-03-05
    */
    gameindexMethod(index) 
    {
      let currentPage = this.gamePage - 0; // 当前页码
      let pageSize = this.gamePageSize - 0; // 每页条数
      return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
    },
    /**
    * @name: 准入证书表格分页序号递增
    * @author: camellia
    * @date: 2021-03-05
    */
    indexMethod(index) 
    {
      let currentPage = this.certPage - 0; // 当前页码
      let pageSize = this.certPageSize - 0; // 每页条数
      return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
    },
    /**
      * @name: 获取安全教育小游戏用户信息
      * @author: lxy
      * @date: 20220507
      */
    getGameDataTnfo(info) 
    {
      let self = this;
      self.user_id = info.userid
      self.gamedataDialogTitle = `操作记录 / ${info.personal_id} ${info.name}`
      self.getGameDataList()
    },
    /**
      * @name: 获取安全教育小游戏列表数据
      * @author: lxy
      * @date: 20220507
      */
    getGameDataList() 
    {
      let self = this;
      let data = {
        user_id: self.user_id,
        currentPage: self.gamedatalistPage
      }
      if (!self.user_id) {
        return self.$Tips({
          // 消息提示内容
          message: '该学生未注册',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 延时时间
          displayTime: 1500
        })
      }
      API.getGameDataList(data)
        .then((res) => {
          if (res.code > 0) {
            self.gamedataList = res.list;
            self.gamedataDialogVisible = true;
            self.gamedatalistTotal = res.number;
          }
        })
    },
    /**
      * @name: 全部解锁
      * @author: lxy
      * @date: 20220507
      */
    showstasticDialogVisible() {
      let self = this;
      // self.virtualDataShow = false;
      self.stasticDialogVisible = true
    },
    /**
      * @name: 全部解锁
      * @author: lxy
      * @date: 20220507
      */
    allUnlock() {
      let self = this
      let data = {
        group_id: self.group_id,
        totalMoneySpent: self.totalMoneySpent
      }
      API.unLockTotalExp(data)
        .then((res) => {
          if (res.code > 0) {
            // Message.success(res.msg)
            self.allunlockDialogVisible = false
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })

          }
          else {
            // return Message.error(res.msg)
            return this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 延时时间
              displayTime: 1500
            })
          }
        })
    },
    /**
     * @name: 下载证书
     * @author: camellia
     * @date: 2022-03-14
     */
    downloadCertZip() {
      const self = this;
      let data = {
        group_id: self.group_id,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show()
      // 请求数据
      API.downloadCertPdf(data)
        .then((result) => {
          if (result.code > 0) {
            // Message.success(result.msg);
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
          }
          else {
            self.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 延时时间
              displayTime: 1500
            })
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide()
        })
        .catch((err) => {
          nprogressClose();
          self.$Loading.hide()
        });
    },
    /**
     * @name: 选择小游戏活动
     * @author: 兰新宇
     * @date: 20210928
     */
    /*selectActive(){
      let self = this;
      let data = {
        activity_id : self.game_type_id
      }
      console.log(data);
      if(self.game_type_id > 5 )
      {
        API.getActivityList(data)
          .then((res)=>{
            self.gameActivelist = res.list;
          })
      }
    },//*/
    /**
     * @name: 项目列表拖拽后重新排序
     * @author: lxy
     * @date: 20210928
     */
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },
    /**
     * @name: 实现表格拖拽排序
     * @author: lanxinyu
     * @date: 2021-09-24
     */
    rowDrop() {
      const self = this;
      const tbody = document.querySelector(".sortableTable");
      // Sortable.create(tbody);
      const sortable = new self.Sortable(tbody, {
        onEnd: (evt) => {
          // 监听拖动结束事件
          let data = {};
          let sortNo = "";
          let id = "";
          // evt.oldIndex——当前行的被拖拽前的顺序;
          // evt.newIndex——当前行的被拖拽后的顺序;
          if (evt.oldIndex < evt.newIndex) {
            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果;
            window.sessionStorage.setItem("loading", "false");
            if (evt.newIndex == self.groupList2.length - 1) {
              sortNo = self.groupList2[evt.newIndex].sort_no - 1;
              id = self.groupList2[evt.oldIndex].id;
              self.groupList2[evt.oldIndex].sort_no = sortNo;
              // 防止拖拽后从后台获取最新数据页面刷新,影响用户体验,前端处理排序
              self.groupList2 = self.groupList2.sort(self.compare("sort_no"));
            } else {
              let sortbefore = self.groupList2[evt.newIndex].sort_no - 0;
              let sortnext = self.groupList2[evt.newIndex + 1].sort_no - 0;
              sortNo = (sortbefore + sortnext) / 2;
              id = self.groupList2[evt.oldIndex].id;
              self.groupList2[evt.oldIndex].sort_no = sortNo;
              self.groupList2 = self.groupList2.sort(self.compare("sort_no"));
            }
            data = {
              id: id,
              sort_no: sortNo,
            };
          } else {
            if (evt.newIndex == 0) {
              sortNo = self.groupList2[evt.newIndex].sort_no - 0 + 1;
              id = self.groupList2[evt.oldIndex].id;
              self.groupList2[evt.oldIndex].sort_no = sortNo;
              self.groupList2 = self.groupList2.sort(self.compare("sort_no"));
            } else {
              let sortbefore = self.groupList2[evt.newIndex].sort_no - 0;
              let sortnext = self.groupList2[evt.newIndex - 1].sort_no - 0;
              sortNo = (sortbefore + sortnext) / 2;
              id = self.groupList2[evt.oldIndex].id;
              self.groupList2[evt.oldIndex].sort_no = sortNo;
              self.groupList2 = self.groupList2.sort(self.compare("sort_no"));
            }
            // 当拖拽完成时不显示loading效果，本地存储loading为false不展示loading效果
            window.sessionStorage.setItem("loading", "false");
            data = {
              id: id,
              sort_no: sortNo,
            };
          }
          // self.axios
          //   .get("/index.php/group/updateSortNo", {
          //     params: data,
          //   })
          API.updateSortNo(data)
            .then((result) => {
              window.sessionStorage.setItem("loading", "true");
            })
            .catch((err) => { });
        },
      });
    },
    // ==============================================================================
    // 右侧虚拟方法
    exportVirtualData() {
      var self = this;
      var url =
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/exportVirtualData?grpid=" +
        self.group_id;
      window.open(url);
    },
    /**
     * @name: 绘制echarts统计图
     * @author: camellia
     * @date: 2021-05-25
     */
    drawEchartsPie1() {
      var self = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie1"));
      // 绘制图表
      myChart.setOption({
        title: {
          subtext: "通关比关卡",
          left: "center",
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ["通关", "剩余"]
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['40%', '50%'],
            // avoidLabelOverlap: false,
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: '40',
            //     fontWeight: 'bold'
            //   }
            // },
            // labelLine: {
            //   show: false
            // },
            data: [
              {
                value: parseFloat(self.virtualData.bz),
                name: "通关",
                itemStyle: { color: '#3E95B5' }
              },
              {
                value: 100 - parseFloat(self.virtualData.bz),
                name: "剩余",
                itemStyle: { color: '#F5BD5C' }
              },
            ],
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
          }
        ],

        // title: {
        //   subtext: "通关比关卡",
        //   left: "center",
        // },
        // tooltip: {
        //   trigger: "item",
        //   formatter: "{a} <br/>{b} : {c} ({d}%)",
        // },
        // legend: {
        //   bottom: 10,
        //   left: "center",
        //   data: ["剩余", "通关"],
        // },
        // series: [
        //   {
        //     name: "",
        //     type: "pie",
        //     radius: "50%",
        //     center: ["50%", "50%"],
        //     avoidLabelOverlap: false,
        //     data: [
        //       {
        //         value: parseInt(self.virtualData.tgsj),
        //         name: "通关",
        //       },
        //       {
        //         value: self.virtualData.gksj - self.virtualData.tgsj,
        //         name: "剩余",
        //       },
        //     ],
        //     label: {
        //       //去除饼图的指示折线label
        //       normal: {
        //         show: false,
        //         position: "inside",
        //         formatter: "{b}:{d}%",
        //       },
        //     },
        //   },
        // ],
      });
      // console.log(parseInt(self.virtualData.bz));
    },
    /**
     * @name: 绘制echarts统计图
     * @author: camellia
     * @date: 2021-05-25
     */
    drawEchartsPie2() 
    {
      var self = this;
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("pie2"));
      // 绘制图表
      myChart.setOption({
        title: {
          subtext: "完成人数统计",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)",
        },
        legend: {
          bottom: 10,
          left: "center",
          data: ["未完成", "完成"],
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: ['40%', '50%'],
            // center: ["50%", "50%"],
            // avoidLabelOverlap: false,
            data: [
              {
                value: self.virtualData.wcr,
                name: "完成",
                itemStyle: { color: '#3E95B5' }
              },
              {
                value: self.virtualData.qzrs - self.virtualData.wcr,
                name: "未完成",
                itemStyle: { color: '#F5BD5C' }
              },
            ],
            label: {
              //去除饼图的指示折线label
              normal: {
                show: false,
                position: "inside",
                formatter: "{b}:{d}%",
              },
            },
          },
        ],
      });
    },
    /**
     * @name: 绘制echarts统计图
     * @author: camellia
     * @date: 2021-05-25
     */
    drawEchartsBar1() 
    {
      var self = this;
      var arr1 = [];
      var arr2 = [];
      for (var i = 0; i < self.virtualData.list.length; i++) {
        arr1.push("关卡" + (i + 1));
        arr2.push(parseFloat((self.virtualData.list[i].pjsj) / 10 * 60));
      }
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("bar1"));
      myChart.setOption({
        title: { text: "平均时间/min" },
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: arr1,
        },
        yAxis: { max: function (value) { return Math.floor(value.max) + 1; }, min: 0 },
        series: [
          {
            barWidth: 16,
            data: arr2,
            type: "bar",
            //颜色
            itemStyle: {
              normal: {
                color: "#3E95B5",
              },
            },
          },
        ],
      });
    },
    /**
     * @name: 统计数据方法
     * @author: camellia
     * @date: 2021-05-25
     */
    courseExpCensus() 
    {
      const self = this;
      if (self.virtualDate?.length <= 0) 
      {
        self.$Tips({
          // 消息提示内容
          message: '请选择日期',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
      if (!self.virtualExpId) {
        // Message.warning("请选择虚拟实验！");
        self.$Tips({
          // 消息提示内容
          message: '请选择虚拟实验',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
      let data = {
        stime: self.virtualDate[0],
        etime: self.virtualDate[1],
        exp_id: self.virtualExpId,
        grp_id: self.group_id,
      };

      localStorage.setItem("stime", self.virtualDate[0]);
      localStorage.setItem("etime", self.virtualDate[1]);

      // 开启loading
      nprogressStart();
      self.$Loading.show();
      // 请求数据
      API.CourseExpCensus(data)
        .then((result) => {
          if (result.code > 0) {
            self.virtualDataShow = true;
            self.virtualData = result.data;
            self.drawEchartsPie1();
            self.drawEchartsPie2();
            self.drawEchartsBar1();
          }
          this.$Tips({
            // 消息提示内容
            message: res.msg,
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'success',
            // 延时时间
            displayTime: 1500
          })
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          nprogressClose();
          self.$Loading.hide();
        });
    },

    // ==============================================================================
    // 右侧证书列表方法
    /**
     * @name: 导出学生证书列表
     * @author: camellia
     * @date: 2021-05-25
     */
    exportStudentCertList() 
    {
      var self = this;
      var url =
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/exportStudentCertExcel?grpid=" +
        self.group_id +
        "&game_id=" +
        self.game_id +
        "&game_type_id=" +
        self.game_type_id;
      // +
      // "&activity_cid=" +
      // self.game_active_id;
      // console.log(url);
      window.open(url);
    },
    /**
     * @name: 证书列表分页
     * @author: camellia
     * @date: 2021-04-07
     */
    certPageChange(val) 
    {
      const self = this;
      self.certPage = val;
      self.getCertReckonList();
    },
    /**
     * @name: 安全准入搜索学生
     * @author: camellia
     * @date: 2021-04-07
     */
    searchCertReckonList() 
    {
      this.certPage = 1;
      this.getCertReckonList();
    },
    /**
     * @name: 获取证书列表
     * @author: camellia
     * @date: 2021-04-07
     */
    getCertReckonList() 
    {
      const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        course_id: self.cert_course_id,
        page: self.certPage,
        pagesize: self.certPageSize,
        search: self.certSearch
      };
      // 请求数据
      self.$router.push({
        path: "/teacher/other/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      if (!self.isShow) {
        return;
      }
      else {
        nprogressStart();
        self.$Loading.show();
        API.getCertReckonList(data)
          .then((result) => {
            if (result.code > 0) {
              self.certTableData = result.group;
              self.certTotal = result.total;
              self.certInfo = result.certInfo;
              self.gamelist = result.gamelist;
              self.game_id = result.gamelist[0].id;
              self.certNum = result.certNum;
            }
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            nprogressClose();
            self.$Loading.hide();
          });
      }

    },
    /**
     * @name: 获取小游戏类型列表
     * @author: camellia
     * @date: 2021-04-07
     */
    getGameTypeList() 
    {
      const self = this;
      let data = {
        game_id: self.game_id,
      };
      // 请求数据
      API.getGameTypeList(data)
        .then((result) => {
          self.game_type_id = "";
          if (result.code > 0) {
            self.gameTypelist = result.gameTypelist;
          }
        })
        .catch((err) => {
        });
    },
    // ==============================================================================
    // 右侧游戏列表方法
    /**
     * @name: 游戏列表分页
     * @author: camellia
     * @date: 2021-04-07
     */
    gamePageChange(val) {
      const self = this;
      self.gamePage = val;
      self.getGameList();
    },
    /**
     * @name: 游戏列表
     * @author: camellia
     * @date: 2021-04-06
     */
    getGameList() {
      const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        page: self.gamePage,
        pagesize: self.gamePageSize,
      };

      self.$router.push({
        path: "/teacher/other/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      if (!self.isShow) {
        return;
      }
      else {
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.getGameList(data)
          .then((result) => {
            if (result.code > 0) {
              self.gameTableData = result.group;
              self.gameTotal = result.total;
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      }

    },
    // ==============================================================================
    // 右侧成绩列表方法
    /**
     * @name: 成绩分页
     * @author: camellia
     * @date: 2021-04-06
     */
    scorePageChange(val) {
      const self = this;
      self.scorePage = val;
      // 全选选项默认不选择
      self.checkAll = false;
      self.getReleaseScore();
    },
    /**
     * @name: 成绩搜索学生
     * @author: camellia
     * @date: 2021-04-02
     */
    searchStudent() {
      this.scorePage = 1;
      this.getReleaseScore();
    },
    /**
     * @name: 获取已经发布的成绩
     * @author: camellia
     * @date: 2021-04-02
     */
    getReleaseScore() {
      const self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        page: self.scorePage,
        pagesize: self.scorePageSize,
        name: self.studentGroupSearch,
        lookType: self.lookType,
      };
      self.$router.push({
        path: "/teacher/other/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      if (!self.isShow) {
        return;
      }
      else {
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.showUNreleased(data)
          .then((result) => {
            self.$Loading.hide();
            self.scoreTableTitleList = result.proarr;
            self.scoreTableTitleList?.forEach((item) => {
              // 解析字符串中的权重
              item.weight = (parseFloat(item.name.substring(item.name.indexOf("*") + 1))  * 100).toFixed(1);
              // 解析字符串中的学时
              item.hours = item.name.substring(item.name.indexOf("*") + 1).substring(item.name.substring(item.name.indexOf("*") + 1).indexOf("("));
              // 作为前端循环的key，需要不一样数据才能更新
              item.keys = item.id + Math.random();
              // if (item.name.substring(item.name.indexOf("*") + 1).substring(0, item.name.substring(item.name.indexOf("*") + 1).indexOf("(")) > 1) 
              // {
              //   item.checked = true
              // }
              // else 
              // {
              //   item.checked = false
              // }
            })
            self.scoreTableData = result.releasearr;
            self.studentListTableData = result.releasearr;
            self.scoreTotal = result.total;
            nprogressClose();
            self.$Loading.hide();
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
          })
          .catch((err) => {
            nprogressClose();
            self.$Loading.hide();
          });
      }

    },

    // ==============================================================================
    // 右侧学生列表方法
    /**
     * @name: 上传成绩excel成功
     * @author: camellia
     * @date: 2021-03-01
     * @param:	res	json	上传图片返回信息
     */
    markHandleAvatarSuccess(res) {
      const self = this;
      if (res.code > 0) {
        self.groupFilePath = res.path;
        // Message.success(res.msg);
        this.$Tips({
          // 消息提示内容
          message: res.msg,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'success',
          // 延时时间
          displayTime: 1500
        })
      } else {
        this.$Tips({
          // 消息提示内容
          message: res.msg,
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 延时时间
          displayTime: 1500
        })
      }
    },
    /**
     * @name: 删除文件
     * @author: camellia
     * @date: 2021-03-05
     * @param:	file	json	删除的文件对象
     * @param:	fileList	json	现存的文件列表
     */
    handleRemove(file, fileList) {
      // console.log(file);
      // console.log(fileList);
    },
    /**
     * @name: 上传文件数量校验
     * @author: camellia
     * @date: 2021-03-05
     * @param:	file	json	上传的文件对象
     * @param:	fileList	json	现存的文件列表
     */
    handleExceed(files, fileList) {
      // Message.warning(
      //   `当前限制选择 1 个文件，本次选择了 ${
      //     files.length
      //   } 个文件，共选择了 ${files.length + fileList.length} 个文件`
      // );
      let self = this;
      self.$Tips({
        // 消息提示内容
        message: `当前限制选择 1 个文件，本次选择了 ${
          files.length
          } 个文件，共选择了 ${files.length + fileList.length} 个文件`,
        // 消息提示类型（success-成功,warning-警告/失败）
        messageType: 'warning',
        // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
        button: [
          {
            type: 1,
            text: '知道了'
          }
        ],
      })
    },
    /**
     * @name: 上传前校验
     * @author: camellia
     * @date: 2021-03-05
     */
    markHandleBeforeUpload(file) {
      const self = this;
      var array = file.name.split(".");
      if (array.length < 2) {
        self.fileList = [];
        self.$Tips({
          // 消息提示内容
          message: '文件错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
      var ext = array[array.length - 1];
      if (ext != "xls" && ext != "xlsx") {
        self.fileList = [];
        // Message.error("文件格式为xls，xlsx，请重试！");
        self.$Tips({
          // 消息提示内容
          message: '文件格式为xls，xlsx，请重试',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        self.fileList = [];
        // Message.error("上传头像图片大小不能超过 5MB!");
        self.$Tips({
          // 消息提示内容
          message: '上传头像图片大小不能超过 5MB!',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
        return;
      }
    },
    /**
     * @name: 删除文件之前执行的方法
     * @author: camellia
     * @date: 2021-03-05
     * @param:	file	json	删除的文件对象
     * @param:	fileList	json	现存的文件列表
     */
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    /**
     * @name: 下载导入成绩模板
     * @author: camellia
     * @date: 2021-03-08
     */
    downloadImportTemplate() {
      const self = this;
      // self.rightStudentDialogVisible = false;
      window.open(
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/downloadImportTemplate"
      );
    },
    /**
     * @name: 导出学生班级列表
     * @author: camellia
     * @date: 2021-03-08
     */
    exportStudentListScore() {
      this.scoreDialogVisible = true;
      this.rawScoreSign = false;
      this.calcScoreSign = false;
      this.openTimeSign = false;
    },
    /**
     * @name: 导出学生列表弹窗点击确定
     * @author: camellia
     * @date: 2021-03-08
     */
    exportStudentListHandleOk()
    {
      var self = this;
      if(self.rawScoreSign == false && self.calcScoreSign == false && self.openTimeSign == false)
      {
        this.$Tips({
          // 消息提示内容
          message: "请选择导出数据！",
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 延时时间
          displayTime: 1500
        });return;
      }
      let rawScoreSign = self.rawScoreSign ? 1 : 0;
      let calcScoreSign = self.calcScoreSign ? 1 : 0;
      let openTimeSign = self.openTimeSign ? 1 : 0;
      var url =
        process.env.VUE_APP_DOMAIN_URL +
        "/backend/public/index.php/group/exportExcel?grpid=" +
        self.group_id +'&rawScoreSign='+rawScoreSign+'&calcScoreSign='+calcScoreSign+'&openTimeSign='+openTimeSign;
      window.open(url);
      self.scoreDialogVisible = false;
    },
    /**
     * @name: 查询内容
     * @author: camellia
     * @date: 2021-04-02
     */
    searchContent() {
      this.getGroupInfo(this.group_id);
    },
    /**
     * @name: 设置自动刷分
     * @author: camellia
     * @date: 2021-05-13
     */
    setAutoBrushPoint() {
      const self = this;
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      var data = {
        student_group_id: self.group_id,
      }
      // 解锁全部试验
      API.createExpRecord(data)
        .then((result) => {
          if (result.code > 0) {
            self.rightStudentDialogVisible = false;
            self.getGroupInfo(self.group_id);
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        });
    },
    /**
     * @name: 取消自动刷分
     * @author: camellia
     * @date: 2021-05-13
     */
    removeAutoBrushPoint() {
      const self = this;
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      // 解锁全部试验
      API.delStudentBrush(data)
        .then((result) => {
          if (result.code > 0) {
            self.rightStudentDialogVisible = false;
            self.getGroupInfo(self.group_id);
            this.$Tips({
              // 消息提示内容
              message: res.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        });
    },
    /**
     * @name: 右侧学生列表点击确定
     * @author: camellia
     * @date: 2021-04-01
     */
    rightStudentHandleExec() {
      const self = this;
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      //  导入
      if (self.rightStudentDialogSign == "import") {
        let data = {
          group_id: self.group_id,
          filePath: self.groupFilePath,
        };
        // 请求数据
        API.updateStudentGroup(data)
          .then((result) => {
            if (result.code > 0) {
              self.rightStudentDialogVisible = false;
              self.getGroupInfo(self.group_id);
              self.fileList = [];
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      } else if (self.rightStudentDialogSign == "delete") {
        // 删除
        let data = {
          group_id: self.group_id,
          user_id_array: self.checkboxArr,
        };
        // 请求数据
        API.deleteStudent(data)
          .then((result) => {
            if (result.code > 0) {
              self.rightStudentDialogVisible = false;
              self.getGroupInfo(self.group_id);
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      } else if (self.rightStudentDialogSign == "markRelease") {
        // 发布成绩
        let data = {
          group_id: self.group_id,
        };
        // 请求数据
        API.markRelease(data)
          .then((result) => {
            if (result.code > 0) {
              self.rightStudentDialogVisible = false;
              self.getReleaseScore(self.group_id);
              this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            else {
              self.rightStudentDialogVisible = false;
              this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      } else if (self.rightStudentDialogSign == "insert") {
        let data = {
          group_id: self.group_id,
          xuehao: self.studentNumber,
        };
        // 添加学生
        API.addStudentToStuGrpDetail(data)
          .then((result) => {
            if (result.code > 0) {
              self.rightStudentDialogVisible = false;
              self.getGroupInfo(self.group_id);
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      } else if (self.rightStudentDialogSign == "unlock") {
        let data = {
          group_id: self.group_id,
          cost: self.lockPoint,
        };
        // 解锁全部试验
        API.purchaseExpCourse(data)
          .then((result) => {
            if (result.code > 0) {
              self.rightStudentDialogVisible = false;
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            self.$Loading.hide();
          });
      } else if (self.rightStudentDialogSign == "exportCert") {
        if (self.game_id == "" || self.game_type_id == "") {
          // 关闭loading
          self.$Loading.hide();
          // Message.warning("请选择您要导出的小游戏及小游戏类型！");
          this.$Tips({
            // 消息提示内容
            message: "请选择您要导出的小游戏及小游戏类型",
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 延时时间
            displayTime: 1500
          })
          return;
        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
        self.exportStudentCertList();
      }
    },
    /**
     * @name: 右侧学生列表打开弹窗
     * @author: camellia
     * @date: 2021-04-02
     */
    rightStudentDialogOpen(sign, title) {
      const self = this;
      self.getGameTypeList();
      if (sign == 'markRelease') {
        if (self.studentListTableData && self.studentListTableData.length == 0) {
          return self.$Tips({
            // 消息提示内容
            message: '该班级暂无学生信息',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
        }
        else if (self.studentListTableData) {
          let index = self.studentListTableData.findIndex((item) => {
            return item.name == "未注册"
          })
          if (index >= 0) {
            this.$Tips({
              // 消息提示内容
              message: '班级成员尚未全部注册',
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
              button: [
                {
                  type: 0,
                  text: '继续发布'
                }
              ],
              callBack: () => {
                self.rightStudentDialogSign = sign;
                self.rightStudentDialogTitle = title;
                self.rightStudentDialogVisible = true;
                self.game_type_id = "";
                self.studentNumber = "";
                if (self.rightStudentDialogSign == "unlock") {
                  let data = {
                    group_id: self.group_id
                  }
                  // 开启loading
                  nprogressStart();
                  self.$Loading.show();
                  // 获取解锁花费的积分
                  API.toGetTeacherBuyAllGrpStuCount(data)
                    .then((result) => {
                      if (result.code > 0) {
                        self.lockPoint = result.cost;
                      }
                      // 关闭loading
                      nprogressClose();
                      self.$Loading.hide();
                    })
                    .catch((err) => {
                      // 关闭loading
                      nprogressClose();
                      self.$Loading.hide();
                    });
                }
              }
            })
          }
          else {
            self.rightStudentDialogSign = sign;
            self.rightStudentDialogTitle = title;
            self.rightStudentDialogVisible = true;
            self.game_type_id = "";
            self.studentNumber = "";
            if (self.rightStudentDialogSign == "unlock") {
              let data = {
                group_id: self.group_id
              }
              // 开启loading
              nprogressStart();
              self.$Loading.show();
              // 获取解锁花费的积分
              API.toGetTeacherBuyAllGrpStuCount(data)
                .then((result) => {
                  if (result.code > 0) {
                    self.lockPoint = result.cost;
                  }
                  // 关闭loading
                  nprogressClose();
                  self.$Loading.hide();
                })
                .catch((err) => {
                  // 关闭loading
                  nprogressClose();
                  self.$Loading.hide();
                });
            }
          }
        }
        else {
          self.rightStudentDialogSign = sign;
          self.rightStudentDialogTitle = title;
          self.rightStudentDialogVisible = true;
          self.game_type_id = "";
          self.studentNumber = "";
          if (self.rightStudentDialogSign == "unlock") 
          {
            let data = {
              group_id: self.group_id
            }
            // 开启loading
            nprogressStart();
            self.$Loading.show();
            // 获取解锁花费的积分
            API.toGetTeacherBuyAllGrpStuCount(data)
              .then((result) => {
                if (result.code > 0) 
                {
                  self.lockPoint = result.cost;
                }
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              })
              .catch((err) => {
                // 关闭loading
                nprogressClose();
                self.$Loading.hide();
              });
          }
        }
      }
      else 
      {
        self.rightStudentDialogSign = sign;
        self.rightStudentDialogTitle = title;
        self.rightStudentDialogVisible = true;
        self.game_type_id = "";
        // self.game_active_id = '';
        // self.game_id = "";
        self.studentNumber = "";
        if (self.rightStudentDialogSign == "unlock") 
        {
          let data = {
            group_id: self.group_id
          }
          // 开启loading
          nprogressStart();
          self.$Loading.show();
          // 获取解锁花费的积分
          API.toGetTeacherBuyAllGrpStuCount(data)
            .then((result) => {
              if (result.code > 0) 
              {
                self.lockPoint = result.cost;
              }
              // 关闭loading
              nprogressClose();
              self.$Loading.hide();
            })
            .catch((err) => {
              // 关闭loading
              nprogressClose();
              self.$Loading.hide();
            });
        }
      }

    },
    handleSelectionChange(val) 
    {
      let self = this;
      self.multipleSelection = val
      self.checkboxArr = self.multipleSelection.map((item) => { return item.id })
    },
    /**
     * @name: 多选框值发生变化
     * @author: camellia
     * @date: 2021-04-01
     */
    checkboxChange(e, param) 
    {
      const self = this;
      if (e) 
      {
        self.checkboxArr.push(param);
      } 
      else 
      {
        let index = self.checkboxArr.indexOf(param);
        self.checkboxArr.splice(index, 1);
      }
      // ============================================================
      // 判断全选展示
      for (let i = 0; i < self.studentListTableData.length; i++) 
      {
        if (self.studentListTableData[i].checkModel !== e) 
        {
          self.checkAll = false;
          return;
        }
      }
      if (self.checkboxArr.length != 0) 
      {
        self.checkAll = true;
      }
    },
    /**
     * @name: 全选点击事件
     * @author: camellia
     * @date: 2021-01-06 09:53:09
     * @param:	sign	boolen	true/false
     */
    handleCheckAllChange(sign) 
    {
      const self = this;
      if (self.studentListTableData.length <= 0) 
      {
        return;
      }
      for (let i = 0; i < self.studentListTableData.length; i++) 
      {
        self.studentListTableData[i].checkModel = sign;
        self.checkboxArr.push(self.studentListTableData[i].id);
      }
      if (!sign) 
      {
        self.checkboxArr = [];
      }
    },
    /**
     * @name: 展示恢复班级
     * @author: LXY
     * @date: 2021-01-06 09:53:09
     */
    hideGroup(id, courseid, name = "", sign = "", type = true) 
    {
      let self = this;
      self.isShow = false;
      sleep(800).then(() => {
        self.updateCssStyle(id);
      });
      self.group_id = id;
      self.left_group_id = id;
      self.courseid = courseid;
      self.cert_course_id = courseid;
      self.rightContentShow = false;
      self.expList = [];
      // 筛选日期数组['开始时间','结束时间']
      self.virtualDate = [];
      // 虚拟实验id
      self.virtualExpId = "";
      // 虚拟数据展示
      self.virtualDataShow = false;
      if (sign == "delete") 
      {
        self.groupName = name;
        self.rightContentShow = false;
      }
      let data = {
        id: self.group_id,
        page: self.studentListPage,
        pagesize: self.studentListPageSize,
        search: self.studentListSearch,
      };
      // 开启loading
      self.activeName = 'first';
      self.$router.push({
        path: "/teacher/other/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      return;
    },
    /**
     * @name: 获取班级列表
     * @author: camellia
     * @date: 2021-02-26
     * @param:	id	int	班级iid
     * @param:	courseid	int	课程id
     * @param:	name	string	班级名称
     * @param:	sign	string	操作标识
     */
    getGroupInfo(id, courseid, name = "", sign = "", type = true) {
      // 请求数据
      const self = this;
      if (!type) 
      {
        self.isShow = false;
        sleep(800).then(() => {
          self.updateCssStyle(id);
        });
        self.group_id = id;
        self.cert_course_id = courseid;
        self.rightContentShow = false;
        self.expList = [];
        // 筛选日期数组['开始时间','结束时间']
        self.virtualDate = [];
        // 虚拟实验id
        self.virtualExpId = "";
        // 虚拟数据展示
        self.virtualDataShow = false;
        if (sign == "delete") {
          self.groupName = name;
          self.rightContentShow = false;
        }
        let data = {
          id: self.group_id,
          page: self.studentListPage,
          pagesize: self.studentListPageSize,
          search: self.studentListSearch,
        };
        // 开启loading
        self.activeName = 'first';
        self.$router.push({
          path: "/teacher/other/xueqing",
          query: {
            group_id: self.group_id,
            activeName: self.activeName,
          },
        });
        return;
      }
      else 
      {
        sleep(800).then(() => {
          self.updateCssStyle(id);
        });
        self.group_id = id;
        self.cert_course_id = courseid;
        self.rightContentShow = true;
        self.expList = [];
        // 筛选日期数组['开始时间','结束时间']
        self.virtualDate = [];
        // 虚拟实验id
        self.virtualExpId = "";
        // 虚拟数据展示
        self.virtualDataShow = false;
        if (sign == "delete") 
        {
          self.groupName = name;
          self.rightContentShow = false;
        }
        let data = {
          id: self.group_id,
          page: self.studentListPage,
          pagesize: self.studentListPageSize,
          search: self.studentListSearch,
        };
        // 开启loading
        self.activeName = 'first';
        self.$router.push({
          path: "/teacher/other/xueqing",
          query: {
            group_id: self.group_id,
            activeName: self.activeName,
          },
        });
        nprogressStart();
        self.$Loading.show();
        API.getGroupStudentInfo(data)
          .then((result) => {
            if (result.code > 0) 
            {
              self.classCourseList = result.classCourseList
              self.studentListTableData = result.group;
              self.studentListTotal = result.total;
              self.activeName = "first";
              self.student_group_name = result.student_group_name;
              self.studentNum = result.studentNum;
              self.studentRegNum = result.studentRegNum;
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      }

    },
    /**
     * @name: 跳转至课程修改页
     * @author: camellia
     * @date: 2021-05-29 
     * @param:	project_id	number	项目id
     */
    jumpToCourse(course_id) 
    {
      window.open("#/teacher/course?course_id=" + course_id + '&activeName=' + 'first' + '&sign=' + '-3')
    },
    // ====================================================================================
    // 左侧项目列表 方法
    classChangeOption() 
    {
      this.getGroupList();
    },
    /**
     * @name: 清空课程下拉列表选项
     * @author: camellia
     * @date: 2021-03-02
     */
    classClearOption() 
    {
      this.getGroupList();
    },
    /**
     * @name: 修改排序(不用了)
     * @author: camellia
     * @date: 2021-04-01
     */
    sortNoUpdate(id, sort_no) {
      const self = this;
      let data = {
        id: id,
        sort_no: sort_no,
      };
      API.updateSortNo(data)
        .then((result) => {
          self.getGroupList();
        })
        .catch((err) => { });
    },
    /**
     * @name: 更改css样式
     * @author: camellia
     * @date: 2021-03-03
     * @param:	id	type	description
     */
    updateCssStyle(id) 
    {
      // css效果
      var d = document.querySelectorAll(".left-module tr");
      for (var p = d.length; p--;) 
      {
        if (d[p].id != id) 
        {
          d[p].style.backgroundColor = "#FFFFFF"; /*其他班级*/
          d[p].classList.remove("selectTr");
        } 
        else 
        {
          d[p].style.backgroundColor = "#E5ECF1"; /*点击的班级*/
          d[p].classList.add("selectTr");
        }
      }
    },
    /**
     * @name: 班级学生列表分页值发生改变
     * @author: camellia
     * @date: 2021-04-01
     */
    studentListPageChange(val) 
    {
      const self = this;
      self.studentListPage = val;
      // 全选选项默认不选择
      self.checkAll = false;
      self.getGroupInfo(self.group_id);
    },
    /**
     * @name: 点击切换标签页事件
     * @author: camellia
     * @date: 2021-02-26
     * @param:	tab	object	vue对象
     * @param:	event	object	事件
     */
    tabHandleClick(tab, event) 
    {
      const self = this;
      if (tab.name == "first") 
      {
        self.getGroupInfo(self.group_id);
      }
      else if (tab.name == "second") 
      {
        // self.virtualDataShow = false;
        self.getReleaseScore();
      }
      else if (tab.name == "third") 
      {
        self.getGameList();
      }
      else if (tab.name == "fourth") 
      {
        self.getCertReckonList();
      }
      else if (tab.name == "fifth") 
      {
        self.virtualDataShow = false;
        self.virtualExpId = '';
        self.getExpList();
      }
    },
    /**
      * @name: 展示全部解锁对话框
      * @author: lxy
      * @date: 20220507
      */
    showallunlockDialogVisible() 
    {
      if (!this.group_id) 
      {
        return self.$Tips({
          // 消息提示内容
          message: '请选择班级',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
          button: [
            {
              type: 1,
              text: '知道了'
            }
          ],
        })
      }
      this.allunlockDialogVisible = true
    },
    /**
     * @name: 获取虚拟实验列表
     * @author: LXY
     * @date: 20220621
     */
    getVirtualDataList() 
    {
      let self = this;
      sleep(1000).then(() => {
        self.updateCssStyle(self.group_id);
      });
      let data = {
        group_id: self.group_id,
        search: self.virtualSearch
      };
      self.$router.push({
        path: "/teacher/other/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
        },
      });
      nprogressStart();
      self.$Loading.show();
      API.getVirtualDataList(data)
        .then((res) => {
          if (res.code > 0) 
          {
            self.expprojectList = res.projectList
            self.balance = res.balance
            self.lockNum = res.lockNum
            self.totalMoneySpent = res.totalMoneySpent
          }
          nprogressClose();
          self.$Loading.hide();
        })
        .catch(() => {
          nprogressClose();
          self.$Loading.hide();
        })
    },
    /**
     * @name: 获取虚拟列表
     * @author: camellia
     * @date: 2021-05-14
     */
    getExpList() {
      const self = this;
      let data = {
        group_id: self.group_id,
      };
      self.$router.push({
        path: "/teacher/other/xueqing",
        query: {
          group_id: self.group_id,
          activeName: self.activeName,
          exp_id: self.virtualExpId,
        },
      });
      if (!self.isShow) 
      {
        return;
      }
      else 
      {
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        API.getExpList(data)
          .then((result) => {
            nprogressClose();
            if (result.code > 0) 
            {
              self.virtualDate = [];
              self.virtualDate.push(localStorage.getItem("stime") ?? '');
              self.virtualDate.push(localStorage.getItem("etime") ?? '');
              self.expList = result.data ? result.data : [];
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      }

    },
    /**
     * @name: 已删除项目显示/取消显示
     * @author: camellia
     * @date: 2021-02-26
     */
    deleteGroupShow() {
      const self = this;
      self.deleteGroupShowSign = !self.deleteGroupShowSign;
    },
    /**
     * @name: 左侧操作弹窗关闭
     * @author: camellia
     * @date: 2023-03-02
     */    
    leftDialogVisibleClose()
    {
      this.leftDialogVisible = false;
      this.groupName = '';
      this.groupType = '';
      this.course_id = '';
      this.left_group_id = '';
    },
    /**
     * @name: 左侧班级列表操作弹窗
     * @author: camellia
     * @date: 2021-03-29
     * @param:	sign	str	操作标识
     * @param:	title	str	操作标题
     * @param:	id	int	操作id（group_id）
     * @param:	id	int	操作name（groupName）
     * @param:	courseid	int	课程id
     */
    leftHandleOpen(sign, title, id = "", name = "", courseid = "", groupType = "") {
      const self = this;
      self.leftDialogVisible = true;
      self.leftDialogSign = sign;
      self.leftDialogTitle = title;
      self.left_group_id = id ? id : self.left_group_id;
      self.groupName = name ? name : self.groupName;
      self.groupType = groupType ? groupType : self.groupType;
      self.course_id = courseid ? courseid : self.course_id;
    },
    /**
     * @name: 左侧班级列表弹窗点击确定
     * @author: camellia
     * @date: 2021-03-30
     * @param:	data	type	description
     * @return:	data	type	description
     */
    leftHandleExec() 
    {
      const self = this;
      let data = {
        name: self.groupName,
        id: self.left_group_id,
        course_id: self.course_id,
        handleSign: self.leftDialogSign,
        type: self.groupType
      };
      if (self.leftDialogSign == "insert" || self.leftDialogSign == "rename") 
      {
        // 写入/重命名
        if (self.groupName == "") 
        {
          self.$Tips({
            // 消息提示内容
            message: '请输入班级名称',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
          return;
        }
        if (!self.course_id) 
        {
          // Message.warning("请选择班级所属课程！");
          self.$Tips({
            // 消息提示内容
            message: '请选择班级所属课程',
            // 消息提示类型（success-成功,warning-警告/失败）
            messageType: 'warning',
            // 操作按钮组（type:按钮类型（0-灰色按钮，1-蓝色按钮），text:按钮文字）
            button: [
              {
                type: 1,
                text: '知道了'
              }
            ],
          })
          return;
        }
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        // 请求数据
        API.updateGroup(data)
          .then((result) => {
            if (result.code > 0) 
            {
              // 弹窗显示标识
              self.leftDialogVisible = false;
              self.groupName = '';
              self.course_id = '';
              self.groupType = '';
              // 重载班级列表数据
              self.getGroupList();
              self.left_group_id = '';
              // Message.success(result.msg);
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      } else if (
        self.leftDialogSign == "delete" ||
        self.leftDialogSign == "delete_completely" ||
        self.leftDialogSign == "recover"
      ) 
      {
        // 删除 / 彻底删除 / 恢复
        // 开启loading
        nprogressStart();
        self.$Loading.show();
        API.updateGroupExec(data)
          .then((result) => {
            if (result.code > 0) 
            {
              // 弹窗显示标识
              self.leftDialogVisible = false;
              // 重载班级列表数据
              self.getGroupList();
              // Message.success(result.msg);
              this.$Tips({
                // 消息提示内容
                message: res.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            else 
            {
              this.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 延时时间
                displayTime: 1500
              })
            }
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          })
          .catch((err) => {
            // 关闭loading
            nprogressClose();
            self.$Loading.hide();
          });
      }
    },
    /**
     * @name: 获取项目列表数据
     * @author: camellia
     * @date: 2021-02-26
     */
    getGroupList() 
    {
      const self = this;
      let data = {
        course_id: self.class_id,
      };
      sleep(800).then(() => {
        self.updateCssStyle(self.group_id);
      });
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      // 请求数据
      API.getGroupList(data)
        .then((result) => {
          if (result.code > 0) {
            self.classList = result.courseList;
            self.groupList = result.underList;
            // 深拷贝班级列表数据
            self.groupList2 = JSON.parse(JSON.stringify(self.groupList));
            if(self.groupList.length > 0)
              {
                self.group_id = self.groupList[0].id;
                if(self.group_id != "" || self.group_id != 0 || self.group_id != null || self.group_id != undefined)
                {
                  if (self.groupList.length > 0 ) 
                  {
                    sleep(50).then(() => {
                      self.updateCssStyle(self.groupList[0].id);
                    });
                  }
                }
              }
            self.deleteGroupList = result.historyList;
            self.deleteNum = result.historyNum;

            self.cert_course_id = result.course_id;
            console.log(self.activeName)
            if (self.activeName == 'first' || self.activeName == '' || self.activeName == undefined || self.activeName == 0) 
            {
              self.activeName = 'first';
              // 请求项目信息
              if (self.group_id) 
              {
                self.getExpList(self.group_id, self.cert_course_id);
              }
              else 
              {
                self.getExpList(self.groupList[0].id, self.cert_course_id);
              }
            }
            else if (self.activeName == "second") 
            {
              self.getReleaseScore();
            }
            else if (self.activeName == "third") 
            {
              self.getGameList();
            }
            else if (self.activeName == "fourth") 
            {
              self.getCertReckonList();
            }
            else if (self.activeName == "fifth") 
            {
              self.getExpList()
            }
          }
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        });
    },
    /**
     * @name: 全屏显示学生成绩
     * @author: camellia
     * @date: 2021-02-26
     */
    showAllSceen() 
    {
      let group = this.groupList.find((item) => {
        return item.id == this.$router.currentRoute.query.group_id
      })
      window.open("#/teacher/unpublishGrade?hideMenu=1&name=" + group.name + '&group_id=' + group.id)
    },
    /**
     * @name: 打开虚拟实验关卡统计
     * @author: camellia
     * @date: 2023-03-28
     * @param:	index	    number	    关卡
     */
    showExpStatDialog(index=0,page=1)
    {
      const self = this;
      if(index)
      {
        self.echartsDialogIndex = index;
      }
      self.statPage = page;
      nprogressStart();
      self.$Loading.show();
      let data = {
        exp_id: self.virtualExpId,
        CID: self.echartsDialogIndex,
        statPage:self.statPage
      };
      API.getExpCidStat(data)
        .then((result) => {
          if(result.code > 0)
          {
            self.echartsDialogTotal = parseInt(result.total);
            self.echartsDialogVisible = true;
            self.showExpCIDEcharts(result.expStepShow,result.errorRateArray,result.avgTimeArray,index);
          }
          nprogressClose();
          self.$Loading.hide();
        })
        .catch((err) => {
          nprogressClose();
          self.$Loading.hide();
        });
    },
    /**
     * @name: 改变分页
     * @author: camellia
     * @date: 2023-03-29
     * @param:	data	type	description
     */
    changeEchartsPage(e)
    {
      this.showExpStatDialog(this.echartsDialogIndex,e);
    },

    /**
     * @name: 展示虚拟实验统计图表
     * @author: camellia
     * @date: 2023-03-28
     * @param:	expStepShow	        array	  步骤
     * @param:	errorRateArray	    array	  错误率
     * @param:	avgTimeArray	      array	  平均时间
     * @param:	index       	      number	关卡数
     */
    showExpCIDEcharts(expStepShow,errorRateArray,avgTimeArray,index)
    {
      this.$nextTick(() => {
        var chartDom = document.getElementById('mains');
        var myChart = echarts.init(chartDom);
        var option;
        option = {
          title: {
            text: '关卡'+index
          },
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'shadow'
            }
          },
          legend: {},
          grid: {
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
            type: 'value',
            boundaryGap: [0, 0.01]
          },
          yAxis: {
            type: 'category',
            data: expStepShow
          },
          series: [
            {
              name: '平均时间/秒',
              type: 'bar',
              data: avgTimeArray,
              color: ['#3E95B5'],
            },
            {
              name: '错误率%',
              type: 'bar',
              data: errorRateArray,
              color: ['#BA120A'],
            }
          ]
        };
        option && myChart.setOption(option);   
      })         
    },

    // ===================================================================
    /**
     * @name: 展示学生成绩总分分布统计图
     * @author: wyf
     * @data: 2023-05-31
     * @param: group_id 班级id
     */
    shouScoreDialog (group_id) {
      const self = this;
      let group = this.groupList.find((item) => {
        return item.id == this.$router.currentRoute.query.group_id
      })
      // 保存当前班级的名字
      self.groupName = group.name
      // 获取当前班级的成绩
      API.getStatData({group_id:group_id})
      .then(res=>{
        console.log(res);
        self.studentScore = res
        // 渲染echarts图表
        self.$nextTick(()=>{
          // 渲染echarts图表
          self.studentScoreEcharts = echarts.init(document.getElementById('studentScore'));
          let option ={
            title: {
              text: '总分分布统计图',
              left:'center'
            },
            xAxis: {
              data: ['[90,100]', '[80,90)', '[70,80)', '[60,70)', '[0,60)'],
              name:'分数段(%)'
            },
            yAxis: {
              type: 'value',
              name:'人数',
            },
            legend: {
              bottom: '5%'
            },
            series: [
              {
                name:'人数',
                emphasis: {
                  focus: 'series'
                },
                zlevel: 2,
                data: [res.ten_nine_number,res.nine_eight_number,res.eight_seven_number,res.seven_six_number,res.zero_six_number],
                type: 'bar',
                showBackground: true,
                backgroundStyle: {
                  color: 'rgba(180, 180, 180, 0.2)'
                },
                label: {
                  show: true,
                  position: 'top'
                },
                itemStyle: {
                  normal: {
                    //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                    barBorderRadius: [10, 10, 10, 10],
                    color:'#3E95B5',
                    opacity:.9,
                  },
                }, 
                barWidth: 20,
              },
              {
                name:"曲线",
                emphasis: {
                  focus: 'series'
                },
                zlevel: 1,
                data: res.code === -1 ? ['0','0','0','0','0'] : [ res.ten_nine_number, res.nine_eight_number, res.eight_seven_number, res.seven_six_number, res.zero_six_number],
                type:'line',
                smooth: true,
                lineStyle:{
                  color:'#0D5570'
                },
                showSymbol: false,//去除圆点
                areaStyle: {},
                areaStyle: {
                    color: '#C4DFE9'
                  }
              }
            ],
          }
          option && self.studentScoreEcharts.setOption(option);
          // 在页面初始化加载的时候绑定页面resize事件监听 在浏览器窗口发生改变的时候 会触发
          window.addEventListener("resize", () => {
            // 执行echarts自带的resize方法，让echarts图表自适应
            self.studentScoreEcharts.resize();
          })
      })   
      })
      // 打开弹出框
      self.studentScoreDialogVisible=true;
      // 获取当前班级总人数
      API.getGroupStudentInfo({id:group_id}).then(res=>{
        self.studentNum=res.studentNum
      })
    },
  },
  // 组件销毁之前的生命周期函数
  beforeDestroy() 
  {
    /**
     * @name: 页面组件销毁的时候,移除绑定的监听resize事件
     * @author: wyf
     * @data: 2023-06-01
     */
    window.removeEventListener("resize", () => {
      this.studentScoreEcharts.resize();
    });
  },
};
